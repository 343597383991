<br><br>
<h1>Administrative View: Images</h1>
<form [formGroup]="uploadForm" (ngSubmit)="submitForm()">
      <h2>Add New Image to Carousel</h2>
      <input  formControlName="file" name="file" id="file"  type="file"  (change)="onFileChange($event)">{{message}}
    <button class="submitButton" type="submit" [hidden]="newImageCaption ==''">Upload</button>
</form>
<!-- for the database -->
<img [src]="imgURL" height="200" *ngIf="imgURL != undefined || imgURL != ''">
<div *ngIf="picturePreviewed ==true">
      Please enter this photo's caption: limit 40 characters <input type="text" [(ngModel)]="newImageCaption" maxlength="40">
</div>

<br><br>
<div *ngIf="error" class="alert alert-danger">Database says: {{error}}</div>
<div *ngIf="success" class="alert alert-success">Database says: {{success}}</div>

<div class="imageCount">
      Number of images in carousel: {{this.imageLength}}  
</div>
<table mat-table [dataSource]="images"  class="mat-elevation-z8">
      <ng-container matColumnDef="imageString">
        <th mat-header-cell *matHeaderCellDef >Image String</th>
        <td mat-cell *matCellDef="let img">{{img.imageString}}</td>
      </ng-container>
      <ng-container matColumnDef="caption">
        <th mat-header-cell *matHeaderCellDef >Caption</th>
        <td mat-cell *matCellDef="let img">{{img.caption}}</td>
      </ng-container>
      <ng-container matColumnDef="Edit">
            <th mat-header-cell *matHeaderCellDef>Edit</th>
            <td mat-cell *matCellDef="let img"> 
                  <input type="button" value = "Delete" color='primary' (click)='deleteImage(img.ImageID, img.imageString)'>   
            </td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


<br>






<br><br><br><br>