    <div class = "logoButton">
        <div class="logo">
            <img class="logo" src="assets\LWVClarionCounty_rgb.png" alt="logo">
        </div>
    
        <div class = "contactButtonDiv">
            <a  mat-raised-button  (click)="openContactDialog()" class="contactButton"> Contact <br /> Us </a>

        </div>

        <div class = "buttonDiv">
            <a  mat-raised-button  (click)="openMemberDialog()" class="memberButton"> Become a <br /> Member </a>

        </div>

    </div>
  
    <app-carousel></app-carousel>

    <div class="mission">
        <h1>Mission</h1>
        <p>
            The League of Women Voters of Clarion County, a nonpartisan political organization, encourages the informed and active participation of citizens in government, works to increase understanding of major public policy issues, and influences public policy through education and advocacy.
        </p>
        <br>
    </div>
    <br>