<h1 mat-dialog-title cdkFocusInitial>Update Officer: {{data.updateFirstName}} {{data.updateLastName}}</h1>
<div mat-dialog-content class="AddForm">
    <mat-form-field >
        <mat-label>First Name</mat-label>
        <input matInput [(ngModel)]="data.updateFirstName" placeholder="Jane" maxlength="25">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input matInput [(ngModel)]="data.updateLastName" placeholder="Doe" maxlength="25">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Position</mat-label>
        <input matInput [(ngModel)]="data.updatePosition" placeholder="Vice President" maxlength="30">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="data.updateEmail" placeholder="jane.doe@mail.com" maxlength="50">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Term Start</mat-label>
        <input matInput [(ngModel)]="data.updateTermStart" placeholder="01/20" maxlength="10">
    </mat-form-field><br>
    <div mat-dialog-actions>
        <button mat-button (click)="onOkClick()" class ="okButton" [mat-dialog-close]="data"
        [disabled]="data.updateFirstName == '' || data.updateLastName == '' ||
        data.updatePosition == '' || data.updateEmail == '' || data.updateTermStart == ''">Save Officer</button>
        <button mat-button (click)="onNoClick()">Cancel</button>
    </div>
</div>
