<h1 mat-dialog-title cdkFocusInitial>Update Member: {{data.updateFirstName}} {{data.updateLastName}}</h1>
<div mat-dialog-content class="UpdateForm">
    <mat-form-field >
        <mat-label>First Name</mat-label>
        <input matInput [(ngModel)]="data.updateFirstName" maxlength="25">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input matInput [(ngModel)]="data.updateLastName" maxlength="25">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Secondary Household Member</mat-label>
        <input matInput [(ngModel)]="data.updateSecondaryHouseholdMemberName" maxlength="50">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Last Date Paid</mat-label>
        <input matInput [(ngModel)]="data.updateLastPaidDate" maxlength="10">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Date Joined</mat-label>
        <input matInput [(ngModel)]="data.updateDateJoined" maxlength="10">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Membership Type</mat-label>
        <mat-select [(ngModel)]="data.updateMemberShipType">
            <mat-option *ngFor="let type of membershipTypes" [value]='type'>
                {{type}}
            </mat-option>
        </mat-select>
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select [(ngModel)]="data.updateStatus">
            <mat-option *ngFor="let opt of statusOptions" [value]='opt'>
                {{opt}}
            </mat-option>
        </mat-select>
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="data.updateEmail"  maxlength="50">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Preferred Phone</mat-label>
        <input matInput [(ngModel)]="data.updatePreferredPhone"  maxlength="13">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Secondary Phone</mat-label>
        <input matInput [(ngModel)]="data.updateSecondaryPhone"  maxlength="13">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Street Address</mat-label>
        <input matInput [(ngModel)]="data.updateStreetAddress" maxlength="30">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>City</mat-label>
        <input matInput [(ngModel)]="data.updateCity" maxlength="25" maxlength="25">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>State</mat-label>
        <input matInput [(ngModel)]="data.updateState" maxlength="2">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Zip Code</mat-label>
        <input matInput [(ngModel)]="data.updateZipCode" maxlength="5">
    </mat-form-field><br>
    <div mat-dialog-actions>
        <button mat-button (click)="onOkClick()" class ="okButton" [mat-dialog-close]="data"
        [disabled]="data.updateFirstName == '' || data.updateLastName == '' || data.updateSecondaryHouseholdMemberName == '' 
        || data.updateLastPaidDate == '' || data.updateDateJoined == '' || data.updateMemberShipType == '' 
        || data.updateStatus ==''|| data.updateEmail == '' || data.updatePreferredPhone == ''
        || data.updateSecondaryPhone == '' || data.updateStreetAddress == ''
        || data.updateCity == '' || data.updateState == '' || data.updateZipCode == ''">Save Member</button>
        <button mat-button (click)="onNoClick()">Cancel</button>
    </div>
</div>
