
<br><br>
<div>
      <h1>Administrative View: Officers</h1>
      
      <button mat-raised-button (click)="openDialog()">Add New Officer</button>
      
      <br><br>
      <div *ngIf="error" class="alert alert-danger">Database says: {{error}}</div>
      <div *ngIf="success" class="alert alert-success">Database says: {{success}}</div>
      <div class="officerCount">
            Number of officers: {{this.officerLength}}  
      </div>
</div>


<table mat-table [dataSource]="officers"  class="mat-elevation-z8">
      <ng-container matColumnDef="FirstName">
           <th mat-header-cell *matHeaderCellDef >First Name</th>
           <td mat-cell *matCellDef="let off">{{off.FirstName}}</td>
      </ng-container>
      <ng-container matColumnDef="LastName">
            <th mat-header-cell *matHeaderCellDef >Last Name</th>
            <td mat-cell *matCellDef="let off">{{off.LastName}}</td>
      </ng-container>
      <ng-container matColumnDef="Position">
            <th mat-header-cell *matHeaderCellDef >Position</th>
            <td mat-cell *matCellDef="let off">{{off.Position}}</td>
      </ng-container>
      <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef >Email</th>
            <td mat-cell *matCellDef="let off">{{off.Email}}</td>
      </ng-container>
      <ng-container matColumnDef="TermStart">
            <th mat-header-cell *matHeaderCellDef >Start Term</th>
            <td mat-cell *matCellDef="let off">{{off.TermStart}}</td>
      </ng-container>
      <ng-container matColumnDef="Edit">
            <th mat-header-cell *matHeaderCellDef>Edit</th>
            <td mat-cell *matCellDef="let off"> 
                  <input type="button" value = "Delete" color='primary' (click)='deleteOfficer(off.OfficerID)'>   
                  <input type="button" value = "Edit" color='primary' (click)='openUpdateDialog(off)'>   
            </td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>




<br><br><br><br>