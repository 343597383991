<h1 mat-dialog-title>Contact Us</h1>

<mat-dialog-content>
<h2> 

<p>Clarion League of Women Voters <br>E-Mail:<a href="mailto:lwvcco@gmail.com"> lwvcco@gmail.com</a> </p> <br>

<p> <a href="https://www.facebook.com/LWVClarionCounty/" style="text-decoration: none; color: black;"> Follow our Facebook:  </a> 
    <a id = "facebook" href="https://www.facebook.com/LWVClarionCounty/" target="_blank"> <img src="./assets/facebook.png" > </a> </p>
<br>

<p>Please mail membership payments to: </p> <br>
<p class="address">LWV of Clarion County</p>
<p class="address">Janice Horn, Director</p>
<p class="address">32 Barber Street</p>
<p class="address">Clarion, PA 16214</p><br>

</h2>

</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()" id="close">Close</button>
</mat-dialog-actions>
