<p>Please send a check, payable to The League of Women Voters of Clarion County [or LWVCC] and mail it to</p><br>
<p class="address">LWV of Clarion County</p>
<p class="address">Janice Horn, Director</p>
<p class="address">32 Barber Street</p>
<p class="address">Clarion, PA 16214</p><br>
<p>Membership dues and contributions to the League of Women Voters are not deductible as a personal or business expense for tax purposes. </p> <br>
    
<mat-dialog-actions>
    <button class="mat-raised-button"(click)="close()">Cancel</button>
    <button class="mat-raised-button mat-primary" (click)="confirm()" id = "confirm">Confirm Membership</button> <!--email from here?-->
</mat-dialog-actions>

