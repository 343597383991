<body>
  <h1>Links</h1>

  <div class="links-container">
    
    <table>
      <tr >
          <td style="text-align: center;">
            <a href="https://www.votespa.com/" target='_blank'> <img src="./assets/VotesPA.png"> </a>
          </td>
          
          <td style="border:5px solid #005596;">
            <a href="https://www.votespa.com/" target='_blank'>Pennsylvania voting resources</a>
          </td>
      </tr>
  
      <tr>
        <td style="text-align: center;">
          <a href="http://www.co.clarion.pa.us/" target='_blank'> <img src="./assets/ClarionCounty.jpg"> </a>
        </td>
        <td style="border:5px solid #005596;">
          <a href="http://www.co.clarion.pa.us/" target='_blank'> Clarion County </a>
        </td>
      </tr>
  
      <tr>
        <td style="text-align: center;">
          <a href="https://www.palwv.org/" target='_blank'> <img src="./assets/LWVPA.jpg"> </a>
        </td>
        <td style="border:5px solid #005596;">
          <a href="https://www.palwv.org/" target='_blank'> Pennsylvania League of Women Voters </a>
        </td>
      </tr>
  
      <tr>
        <td style="text-align: center;">
          <a href="https://www.lwv.org/" target='_blank'> <img src="./assets/LWV.jpg" > </a>
        </td>
        <td style="border:5px solid #005596;">
          <a href="https://www.lwv.org/" target='_blank'> National League of Women Voters </a>
        </td>
      </tr>

      <tr>
        <td style="text-align: center;">
          <a href="https://www.vote411.org/" target = "_blank"> <img src="./assets/vote411.png" > </a>
        </td>
        <td style="border:5px solid #005596;">
          <a href="https://www.vote411.org/" target = "_blank"> Election Information </a>
        </td>
      </tr>
   
    </table>
  
  </div>

  <h1>Newsletters</h1>

  <div class="accordian-container">
    <mat-accordion>
        <mat-expansion-panel *ngFor="let new of newsletters">
          <mat-expansion-panel-header >
            <mat-panel-title>
                {{new.Title}} 
            </mat-panel-title>
            <mat-panel-description>
              {{new.Description}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="pdfContainer">
            <ng2-pdfjs-viewer 
              #pdfViewerOnDemand
              pdfSrc={{new.pdfSrc}}           
              [download]="true"
              [find] = "false"
              [fullScreen] ="false"
              [openFile] ="false"
              [viewBookmark] = "false"   
            ></ng2-pdfjs-viewer>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <h1>Archives</h1>  
    <div class="accordian-container">
      <mat-accordion>
          <mat-expansion-panel *ngFor="let doc of archives">
            <mat-expansion-panel-header >
              <mat-panel-title>
                  {{doc.Title}} 
              </mat-panel-title>
              <mat-panel-description >
                {{doc.Description}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="pdfContainer">
              <ng2-pdfjs-viewer 
                #pdfViewerOnDemand
                pdfSrc={{doc.pdfSrc}}           
                [download]="true"
                [find] = "false"
                [fullScreen] ="false"
                [openFile] ="false"
                [viewBookmark] = "false"   
              ></ng2-pdfjs-viewer>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

    <h1>Policies</h1>  
    <div class="accordian-container">
      <mat-accordion>
          <mat-expansion-panel *ngFor="let doc of policies">
            <mat-expansion-panel-header >
              <mat-panel-title>
                  {{doc.Title}} 
              </mat-panel-title>
              <mat-panel-description >
                {{doc.Description}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="pdfContainer">
              <ng2-pdfjs-viewer 
                #pdfViewerOnDemand
                pdfSrc={{doc.pdfSrc}}           
                [download]="true"
                [find] = "false"
                [fullScreen] ="false"
                [openFile] ="false"
                [viewBookmark] = "false"   
              ></ng2-pdfjs-viewer>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
  </body>