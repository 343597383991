<h1 mat-dialog-title cdkFocusInitial>Update Event: {{data.updateTitle}}</h1>
<div mat-dialog-content class="AddForm">
    <mat-form-field >
        <mat-label>Title</mat-label>
        <input matInput [(ngModel)]="data.updateTitle" placeholder="Election Day" maxlength="30">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Start Time</mat-label>
        <input matInput [(ngModel)]="data.updateStartTime" placeholder="10/20" maxlength="25">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>End Time</mat-label>
        <input matInput [(ngModel)]="data.updateEndTime" placeholder="10/20" maxlength="25">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput [(ngModel)]="data.updateDescription" placeholder="Vote today!" maxlength="50">
    </mat-form-field><br>

    <div mat-dialog-actions>
        <button mat-button (click)="onOkClick()" class ="okButton" [mat-dialog-close]="data"
        [disabled]="data.updateTitle == '' || data.updateStartTime == '' ||
        data.updateEndTime == '' || data.updateDescription == ''">Save Event</button>
        <button mat-button (click)="onNoClick()">Cancel</button>
    </div>
</div>
