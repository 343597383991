<br><br>

<h1>Administrative View: Admins</h1>

<button mat-raised-button (click)="openDialog()">Add New Admin </button> 
<br><br>
<div *ngIf="error" class="alert alert-danger">Database says: {{error}}</div>
<div *ngIf="success" class="alert alert-success">Database says: {{success}}</div>
<div class="adminCount">
      Number of admins: {{this.adminLength}}  
</div>
<table mat-table [dataSource]="admins" class="mat-elevation-z8">
      <ng-container matColumnDef="User">
            <th mat-header-cell *matHeaderCellDef>User</th>
            <td mat-cell *matCellDef="let adm">{{adm.user}}</td>
      </ng-container>
      <ng-container matColumnDef="Edit">
            <th mat-header-cell *matHeaderCellDef>Edit</th>
            <td mat-cell *matCellDef="let adm"> 
                  <input type="button" value = "Delete" color='primary' (click)='deleteAdmin(adm.AdminID)'>   
            </td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


<br><br>


<br><br><br><br> 