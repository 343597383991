<h1 mat-dialog-title cdkFocusInitial>Add Admin</h1>
<p>Note that whichever logins that are created here will be able to log in to the site and have admin priviledges.</p>
<div mat-dialog-content class="AddForm">
    <mat-form-field >
        <mat-label>User</mat-label>
        <input matInput [(ngModel)]="user" placeholder="admin" maxlength="25">
    </mat-form-field><br>
    <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput [(ngModel)]="password" placeholder="password" maxlength="25">
    </mat-form-field><br>
    <div mat-dialog-actions>
        <button mat-button (click)="onOkClick()" class ="okButton" [mat-dialog-close]="data"
        [disabled]="user == undefined || password == undefined">Add Admin</button>
        <button mat-button (click)="onNoClick()">Cancel</button>
    </div>
</div>