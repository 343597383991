<head><script src="https://www.google.com/recaptcha/api.js" async defer></script></head>
<mat-dialog-content>
    <label>Please fill in all fields. If you are not providing the information asked for, please enter N/A.</label>

<mat-form-field >
    <mat-label>First Name</mat-label>
    <input matInput [(ngModel)]="firstname" placeholder="Jane" maxlength="25">
</mat-form-field><br>
<mat-form-field>
    <mat-label>Last Name</mat-label>
    <input matInput [(ngModel)]="lastname" placeholder="Doe" maxlength="25">
</mat-form-field><br>
<mat-form-field>
    <mat-label>Secondary Household Member</mat-label>
    <input matInput [(ngModel)]="secondaryname" placeholder="John Doe" maxlength="50">
</mat-form-field><br>
<mat-form-field>
    <mat-label>Membership Type</mat-label>
    <mat-select [(ngModel)]="membership">
        <mat-option *ngFor="let type of membershipTypes" [value]='type'>
            {{type}}
        </mat-option>
    </mat-select>
</mat-form-field><br>
<mat-form-field>
    <mat-label>Email</mat-label>
    <input matInput [(ngModel)]="email" placeholder="jane.doe@mail.com" maxlength="50" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
</mat-form-field><br>
<mat-form-field>
    <mat-label>Preferred Phone</mat-label>
    <input matInput [(ngModel)]="phone" placeholder="(123)456-7890" maxlength="13">
</mat-form-field><br>
<mat-form-field>
    <mat-label>Secondary Phone</mat-label>
    <input matInput [(ngModel)]="secondaryphone" placeholder="(123)456-7890" maxlength="13">
</mat-form-field><br>
<mat-form-field>
    <mat-label>Street Address</mat-label>
    <input matInput [(ngModel)]="street" placeholder="1 Main Street" maxlength="30">
</mat-form-field><br>
<mat-form-field>
    <mat-label>City</mat-label>
    <input matInput [(ngModel)]="city" maxlength="25" placeholder="Clarion" maxlength="25">
</mat-form-field><br>
<mat-form-field>
    <mat-label>State</mat-label>
    <input matInput [(ngModel)]="state" placeholder="PA" maxlength="2">
</mat-form-field><br>
<mat-form-field>
    <mat-label>Zip Code</mat-label>
    <input matInput [(ngModel)]="zip" placeholder="16214" maxlength="5">
</mat-form-field><br>
<re-captcha
      [(ngModel)]="captcha"
      name="captcha"
      required
      siteKey="6Ldmj9wZAAAAAMPMWM0CYkzBplWUm90R8GvJbLmz"
    ></re-captcha>

<mat-dialog-actions>
    <button class="mat-raised-button"(click)="close()">Close</button>
    <button mat-raised-button  (click)="saveOpen()" id="save"
        [disabled]="firstname == undefined || lastname == undefined || secondaryname == undefined ||
        email == undefined || phone == undefined || membership == undefined ||
        secondaryphone == undefined || street == undefined ||
        city == undefined || state == undefined || zip == undefined || firstname == '' || lastname == '' || secondaryname == '' ||
        email == '' || phone == '' || membership == '' || secondaryphone == '' || street == '' ||
        city == '' || state == '' || zip == ''">Save</button>
</mat-dialog-actions>
</mat-dialog-content>