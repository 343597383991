<html>
  <body>
    <div class = "NavigationBar" [ngClass]="{'mat-elevation-z5' : true, 'sticky' : isSticky}">
      <div class = "left">
        <div class="navButtons">
          <a mat-button [routerLink]="''" routerLinkActive="active" class="homeButton"> LWV</a>
          <a mat-button [routerLink]="'calendar'" > Calendar </a>
          <a mat-button [routerLink]="'resources'" > Resources </a>
          <a mat-button [routerLink]="'history'" > About Us </a>
          <a mat-button [routerLink]="'admin'" class="adminButton"> Admin</a>
        </div>
      </div>
      <div class = "right">
        <a mat-raised-button href = "https://www.votespa.com/Register-to-Vote/Pages/default.aspx" target = "_blank" class="vote"> Register to Vote! </a>
      </div>
    </div>

   <div class =  pagecontainer>
    <div class="wrapper">
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <router-outlet></router-outlet>
    </div>
    <footer class="footer">
      <a id = "facebook" href="https://www.facebook.com/LWVClarionCounty/" target="_blank"> <img src="./assets/facebook.png" > </a>
      <p id="copyright"> &copy; Copyright 2020 League of Women Voters of Clarion County
      </p>
    </footer>
  </div>

  </body>


</html>