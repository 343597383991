<h1 mat-dialog-title>{{data.title}}</h1>

<mat-dialog-content>
<h2>Event Information: {{data.description}}</h2>

</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()" id="close">Close</button>
</mat-dialog-actions>
