<br><br>
<h1>Administrative View: Events</h1>
<button mat-raised-button (click)="openDialog()">Add New Event</button>

<br><br>
<div *ngIf="error" class="alert alert-danger">Database says: {{error}}</div>
<div *ngIf="success" class="alert alert-success">Database says: {{success}}</div>
<div class="eventCount">
      Number of events: {{this.eventLength}}  
</div>
<table mat-table [dataSource]="events"  class="mat-elevation-z8">
      <ng-container matColumnDef="Title">
           <th mat-header-cell *matHeaderCellDef >Title</th>
           <td mat-cell *matCellDef="let eve">{{eve.title}}</td>
      </ng-container>
      <ng-container matColumnDef="StartTime">
            <th mat-header-cell *matHeaderCellDef >Start Time: YYYY-MM-DDT00:00</th>
            <td mat-cell *matCellDef="let eve">{{eve.start}}</td>
      </ng-container>
      <ng-container matColumnDef="EndTime">
            <th mat-header-cell *matHeaderCellDef>End Time: YYYY-MM-DDT00:00</th>
            <td mat-cell *matCellDef="let eve">{{eve.end}}</td>
      </ng-container>
      <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef >Description</th>
            <td mat-cell *matCellDef="let off">{{off.description}}</td>
      </ng-container>
      <ng-container matColumnDef="Edit">
            <th mat-header-cell *matHeaderCellDef>Edit</th>
            <td mat-cell *matCellDef="let eve"> 
                  <input type="button" value = "Delete" color='primary' (click)='deleteEvent(eve.EventID)'>   
                  <input type="button" value = "Edit" color='primary' (click)='openUpdateDialog(eve)'>   
            </td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


<br><br>



<br><br><br><br> 